<template>
  <div class="page-list">
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in swipeImg" :key="index"
        ><img :src="item" width="100%" height="100%"
      /></van-swipe-item>
    </van-swipe>
      <div class="dev-list">
        <div v-if="isLoading">
          <van-loading class="z-loading" size="24px" vertical
            >数据努力加载中...</van-loading
          >
        </div>
        <div v-else>
          <div v-if="devList.length != 0" class="content-list">
            <div
              :class="{ block: true, 'online-bg': item.devStatus == 'online' }"
              v-for="(item, index) in devList"
              :key="index"
              @click="goDetail(item)"
            >
              <van-row
                :class="item.devStatus == 'online' ? 'online' : 'offline'"
              >
                <van-col span="5">
                  <img :src="thumbShow(item)" width="60px" height="60px" />
                </van-col>
                <van-col span="19">
                  <div class="info-0">
                    <div class="title" v-text="item.devName"></div>
                    <div>设备编号：{{ item.devCode }}</div>
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
          <div v-else><van-empty description="设备列表为空" /></div>
        </div>
      </div>
    <tabBar />
  </div>
</template>

<script>
import tabBar from "@/components/bucket/tabBar.vue";

export default {
  name: "devIndex",
  components: {
    tabBar,
  },
  data() {
    return {
      swipeImg: [
        require("@/assets/bucket/img/list-banner-1.png"),
        require("@/assets/bucket/img/list-banner-2.png"),
        require("@/assets/bucket/img/list-banner-3.png"),
        require("@/assets/bucket/img/list-banner-4.png"),
        require("@/assets/bucket/img/list-banner-5.png"),
      ],
      count: {
        online: 0,
        total: 0,
      },
      devList: [],
      isLoading: false,
      projectList: [],
    };
  },
  methods: {
    // 点击设备进入详情页面
    goDetail(item) {
      if(item.devType === 'lamp'){
        return this.$toast("肩灯设备仅供列表查看")
      }
      if (this.visitor == "1") {
        return this.$toast("暂无权限进入设备操控面板")
      }
      if(item.devStatus === 'offline'){
        return this.$toast('设备离线中，待上线后在进行操作！')
      }
      this.$router.push({
        path: "/bucket/param",
        query: { 
          devCode: item.devCode,
          devName: item.devName
        },
      });
    },
    // 缩略图更新
    thumbShow(item) {
      let img;
      if (item.devType !== "lamp") {
        item.devStatus !== "offline"
          ? (img = require("@/assets/bucket/img/list-cone-online.png"))
          : (img = require("@/assets/bucket/img/list-cone.png"));
      } else {
        item.devStatus !== "offline"
          ? (img = require("@/assets/bucket/img/list-lamp-online.png"))
          : (img = require("@/assets/bucket/img/list-lamp.png"));
      }
      return img;
    },
    // 获取设备
    getDevList() {
      this.isLoading = true;
      this.$api.BUCKET.deviceList({
        projectCode: this.projectCode,
      }).then((d) => {
        this.devList = d;
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.projectCode = window.sessionStorage.getItem("projectCode");
    this.visitor = localStorage.getItem("visitor");
    //获取设备列表
    this.isLoading = true;
    this.getDevList();
  },
};
</script>
<style lang="scss" scoped>
.dev-list {
  margin-bottom: 22%;
  .block {
    border: 1px solid #2c3346;
    background-color: #161a25;
    padding: 10px;
    font-size: 0.8rem;
    text-align: left;
    .online {
      color: #d9d9d9;
    }
    .offline {
      color: #626262;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    .info-0 {
      padding-left: 10px;
    }
    .title {
      font-weight: bold;
      line-height: 2.5;
    }
  }
  .online-bg {
    background-color: #24273a;
  }
  .content-list{
    overflow-y: auto;
    height: 65vh;
  }
}
</style>